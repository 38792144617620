import { ref } from 'vue';
import { saveMilk } from '../../api/list';
import { milkList } from '../../api/list';
import { List } from 'vant';
import { PullRefresh } from 'vant';
import { Notify } from 'vant';
import { router } from '../../router';
import 'vant/es/notify/style';
export default {
  setup() {},

  data() {
    return {
      timeshow: '',
      checkedType: 0,
      milk: 180,
      milkListData: []
    };
  },

  created() {
    console.log('init');
    this.showMilkList();
    this.$nextTick(() => {
      console.log("页面加载完啦~");
    });
  },

  methods: {
    save() {
      if (this.checkedType == undefined || this.checkedType == '') {
        // 成功通知
        Notify({
          type: 'success',
          message: '需要选择类型'
        });
      }

      saveMilk({
        milk: this.milk,
        checkType: this.checkedType
      }).then(res => {
        console.log(res);

        if (res.bizCode == 20000) {
          Notify({
            type: 'success',
            message: '保存成功'
          }); //刷新页面

          this.showMilkList();
        }
      });
    },

    showMilkList() {
      this.milkListData = [];
      milkList({
        pageSize: 10,
        pageNo: 1
      }).then(res => {
        // 获取数据后启动定时器
        var data = res.data.records;
        console.log('=====返回数据=====', data);
        this.milkListData = data; //取喝奶时间

        var timeLast;

        for (var i = data.length - 1; i >= 0; i--) {
          console.log('0000000000', data[i]);

          if (data[i].type == 0) {
            timeLast = data[i].milkLastTime;
          }
        }

        console.log('lasttime=', timeLast);
        var int = self.setInterval(this.clock(timeLast), 1000);
      });
    },

    clock(data) {
      console.log("定时器执行", data);
      var timeStr = data;
      console.log('最后一次喂奶时间', timeStr);
      var date1 = timeStr; //开始时间

      var date2 = new Date(); //结束时间

      var date3 = date2.getTime() - new Date(date1).getTime(); //时间差的毫秒数
      //计算出相差天数

      var days = Math.floor(date3 / (24 * 3600 * 1000)); //计算出小时数

      var leave1 = date3 % (24 * 3600 * 1000); //计算天数后剩余的毫秒数

      var hours = Math.floor(leave1 / (3600 * 1000)); //计算相差分钟数

      var leave2 = leave1 % (3600 * 1000); //计算小时数后剩余的毫秒数

      var minutes = Math.floor(leave2 / (60 * 1000)); //计算相差秒数

      var leave3 = leave2 % (60 * 1000); //计算分钟数后剩余的毫秒数

      var seconds = Math.round(leave3 / 1000);
      console.log("距离上次喂奶 " + days + "天 " + hours + "小时 " + minutes + " 分钟" + seconds + " 秒");
      this.timeshow = "" + hours + "小时 " + minutes + " 分钟";
      console.log('赋值显示字段', this.timeshow);
    }

  }
};